import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { EventFilters } from '@/events/reducer/types';
import { useCallback, useEffect, useState } from 'react';
import { Boundaries, Coordinates } from '@/services/geolocation/types';
import { Geolocation } from '@/services/geolocation';
import { DEFAULT_ZOOM } from '@/constants/defaults';
import { Analytics } from '@/services/analytics';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';
import { searchZipCode } from '@/utilities/zipcode-search';

type UseZipcodeDataProps = {
    onCustomCoordinates: (coordinates: Coordinates, zoomToValue: number) => void;
    afterZipcodeCoordinatesSet: () => void;
};

export const useZipcodeBoundaries = ({
    onCustomCoordinates,
    afterZipcodeCoordinatesSet,
}: UseZipcodeDataProps) => {
    const [loadingZipcodeBoundaries, setLoadingZipcodeBoundaries] = useState<boolean>(false);
    const [boundaries, setBoundaries] = useState<Boundaries | null>(null);
    const [stateFromZipcode, setStateFromZipcode] = useState<string | undefined>(undefined);
    const [searchError, setSearchError] = useState(false);

    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const onZipcodeRemoved = useCallback(() => {
        if (boundaries) {
            Geolocation.getInstance()
                .getCoordinates(stateFromZipcode as string)
                .then((stateCoordinates) => {
                    onCustomCoordinates(stateCoordinates, DEFAULT_ZOOM - 5);
                });
        }

        setBoundaries(null);
        setStateFromZipcode(undefined);
    }, [boundaries, onCustomCoordinates, stateFromZipcode]);

    const onNewBoundariesObtained = useCallback(
        (newBoundaries: Boundaries, newZipCode: string) => {
            setBoundaries(newBoundaries);
            setStateFromZipcode(newBoundaries.state);
            onCustomCoordinates(newBoundaries.center, DEFAULT_ZOOM - 1);
            setLoadingZipcodeBoundaries(false);

            Analytics.getInstance().fireEvent(
                AnalyticsEventName.ZipcodeSearch,
                AnalyticsEventCategory.MapInteraction,
                `User searched for zipcode: ${newZipCode}`,
                {
                    lat: newBoundaries.center.latitude,
                    lng: newBoundaries.center.longitude,
                }
            );
        },
        [onCustomCoordinates]
    );

    const searchZipCodeFromSecondSource = useCallback(
        async (newZipCode: string) => {
            const zipCodeFound = searchZipCode(newZipCode);

            if (!zipCodeFound) {
                console.error('No zipcode found');
                setSearchError(true);
                setLoadingZipcodeBoundaries(false);
                return;
            }

            Geolocation.getInstance()
                .getCoordinates(`${zipCodeFound.city}, ${zipCodeFound.state}`)
                .then((coordinates) => {
                    const newBoundaries: Boundaries = {
                        northwest: {
                            latitude: coordinates.latitude,
                            longitude: coordinates.longitude,
                        },
                        northeast: {
                            latitude: coordinates.latitude,
                            longitude: coordinates.longitude,
                        },
                        southwest: {
                            latitude: coordinates.latitude,
                            longitude: coordinates.longitude,
                        },
                        southeast: {
                            latitude: coordinates.latitude,
                            longitude: coordinates.longitude,
                        },
                        center: {
                            latitude: coordinates.latitude,
                            longitude: coordinates.longitude,
                        },
                        state: zipCodeFound.state.toLowerCase(),
                    };

                    onNewBoundariesObtained(newBoundaries, newZipCode);
                });
        },
        [setSearchError, setLoadingZipcodeBoundaries]
    );

    const onZipcodeChanged = useCallback(
        (newZipCode: string) => {
            setSearchError(false);
            setLoadingZipcodeBoundaries(true);

            Geolocation.getInstance()
                .getCoordinatesFromZipcode(newZipCode)
                .then((newBoundaries) => onNewBoundariesObtained(newBoundaries, newZipCode))
                .catch(() => searchZipCodeFromSecondSource(newZipCode));
        },
        [onCustomCoordinates, searchZipCodeFromSecondSource, onNewBoundariesObtained]
    );

    useEffect(() => {
        if (!filters.zipcode || filters.zipcode.length !== 5) {
            setSearchError(false);
            onZipcodeRemoved();
        }
    }, [filters.zipcode, onZipcodeRemoved]);

    useEffect(() => {
        if (
            filters.zipcode?.length === 5 &&
            boundaries === null &&
            !loadingZipcodeBoundaries &&
            !searchError
        ) {
            onZipcodeChanged(filters.zipcode);
        }
    }, [boundaries, filters.zipcode, loadingZipcodeBoundaries, onZipcodeChanged, searchError]);

    useEffect(() => {
        afterZipcodeCoordinatesSet();
    }, [afterZipcodeCoordinatesSet, boundaries]);

    return boundaries;
};
