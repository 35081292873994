import React, { FunctionComponent, useCallback } from 'react';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';
import { Boundaries } from '@/services/geolocation/types';
import { Button, ButtonType } from '@/components/form-controls/button';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { ViewMode } from '@/events/reducer/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { eventsActions } from '@/events/reducer';

export const ZipcodeChangeModal: FunctionComponent = () => {
    const { width } = useWindowDimensions();

    const dispatch = useDispatch();

    const viewMode: ViewMode = useSelector<RootState, ViewMode>((state) => state.events.viewMode);

    const onClick = useCallback(() => {
        if (width < 768 && viewMode === ViewMode.MAP) {
            dispatch(eventsActions.displayFiltersModal(true));
            return;
        }

        const zipCodeElement = document.getElementById('zipcode-search');

        if (zipCodeElement) {
            zipCodeElement.focus();
        }
    }, []);

    return (
        <div className={`${Styles.LocationNotProvidedOverlay}`}>
            <div className={`${Styles.OverlayModal}`}>
                <img
                    style={{
                        width: '16px',
                        height: '16px',
                    }}
                    alt="info-circle-blue-icon"
                    src={IMAGES.InfoCircleBlueIcon}
                />
                <span>
                    Please enter your zip code for
                    <br /> in-person events near you.
                </span>
                <Button
                    customClassName="px-4 py-2 mt-3"
                    type={ButtonType.PRIMARY_BLUE_OUTLINE}
                    onClick={onClick}
                >
                    Add zip code
                </Button>
            </div>
        </div>
    );
};
