import { useCallback, useEffect } from 'react';
import { eventsActions } from '@/events/reducer';
import { useQueryParams } from '@/utilities/use-query-params';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { QueryParameters } from '@/authentication/reducer/state';
import { EventModel } from '@/events/models';
import { RegistrationStatus } from '@/events/reducer/types';

export const useSelectionOfEvent = () => {
    const { getQueryParameter } = useQueryParams();
    const dispatch = useDispatch();

    const queryParameters = useSelector<RootState, QueryParameters>(
        (state) => state.authentication.queryParameters
    );

    const filteredEventsForMap = useSelector<RootState, EventModel[]>(
        (state) => state.events.filteredEventsForMap
    );

    const selectedEvent = useSelector<RootState, EventModel | undefined>(
        (state) => state.events.selectedEvent
    );

    const handleEventIdParamInUrl = useCallback(() => {
        const eventId = getQueryParameter('event');

        if (eventId) {
            // dispatch(eventsActions.updateEventIdFilter([eventId]));
        }
    }, [dispatch, getQueryParameter]);

    useEffect(() => {
        handleEventIdParamInUrl();
    }, [handleEventIdParamInUrl, filteredEventsForMap]);

    useEffect(() => {
        if (queryParameters.eventId && filteredEventsForMap.length > 0 && !selectedEvent) {
            const eventFound = filteredEventsForMap.find(
                (event) => event.id === queryParameters.eventId
            );

            dispatch(eventsActions.selectEvent(eventFound));
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.VIEWING_DETAILS));
        }
    }, [dispatch, filteredEventsForMap, queryParameters.eventId, selectedEvent]);
};
