import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Styles.module.scss';
import { MemberSearchResultModel } from '@/contacts/models';
import { PotentialMember } from '@/contacts/components/registration-modal/components/potential-member';
import { TranslationService } from '@/services/translation';

type Props = {
    searchResults: MemberSearchResultModel[];
    selectContact: (contact: MemberSearchResultModel) => void;
    selectedContact: MemberSearchResultModel | undefined;
    moveToStepFour: () => void;
};

export const StepTwo: FunctionComponent<Props> = ({
    searchResults,
    selectedContact,
    selectContact,
    moveToStepFour,
}) => {
    return (
        <>
            <Row className={'mx-0'}>
                <Col className={'px-0'}>
                    <span
                        className={`text-dark font-montserrat text-center d-block w-100 ${Styles.ModalTitleStepOne}`}
                    >
                        {TranslationService.getInstance().getPhrase('sign_in.step_two.title')}
                    </span>
                </Col>
            </Row>

            <Row className={'mx-0 pb-4 pt-2'}>
                <Col className={'px-0'}>
                    <span
                        className={`${Styles.RegistrationModalParagraph} text-base d-block w-100 text-center`}
                    >
                        {TranslationService.getInstance().getPhrase('sign_in.step_two.paragraph')}
                    </span>
                </Col>
            </Row>

            {searchResults.map((item: MemberSearchResultModel, index) => (
                <PotentialMember
                    key={item.id}
                    index={index}
                    member={item}
                    onSelect={selectContact}
                    selectedMemberId={selectedContact?.id}
                    moveToStepFour={moveToStepFour}
                />
            ))}
        </>
    );
};
