import React, { FunctionComponent, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Styles from './Styles.module.scss';
import ArrowLeftIcon from '@/assets/icons/ArrowLeftIcon';
import { EventModel } from '@/events/models';
import { TranslationService } from '@/services/translation';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { RegistrationStatus } from '@/events/reducer/types';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { UI_FRAMEWORK_SM_WIDTH } from '@/constants/layout';
import CloseIcon from '@/assets/icons/CloseIcon';

type Props = {
    onClosingModal: () => void;
    event?: EventModel;
};

export const EventDetailsHeader: FunctionComponent<Props> = ({ onClosingModal, event }) => {
    const { width } = useWindowDimensions();

    const registrationStatus = useSelector<RootState, RegistrationStatus>(
        (state) => state.events.registrationStatus
    );

    const title = useMemo(() => {
        if (registrationStatus === RegistrationStatus.CONFIRMING) {
            return event?.isAuthenticatedUserRegistered
                ? TranslationService.getInstance().getPhrase('registration.modals.unregister.title')
                : TranslationService.getInstance().getPhrase('registration.modals.register.title');
        }

        if (registrationStatus === RegistrationStatus.AFTER_CONFIRMATION) {
            return TranslationService.getInstance().getPhrase(
                'registration.after_registration.title'
            );
        }

        return event?.name;
    }, [registrationStatus, event]);

    return (
        <Modal.Header className={`px-0 ${Styles.EventDetailsHeader}`}>
            {width < UI_FRAMEWORK_SM_WIDTH && (
                <div className={'w-100'}>
                    <Row className={'mx-0'}>
                        <Col className={'d-flex align-items-center px-3 w-100'}>
                            <span onClick={onClosingModal}>
                                <ArrowLeftIcon />
                            </span>

                            {[
                                RegistrationStatus.CONFIRMING,
                                RegistrationStatus.AFTER_CONFIRMATION,
                            ].includes(registrationStatus) && (
                                <h4 className={`ps-2 mb-0 text-center w-100 ${Styles.EventTitle}`}>
                                    {title}
                                </h4>
                            )}
                        </Col>
                    </Row>

                    {registrationStatus === RegistrationStatus.VIEWING_DETAILS && (
                        <Row className={'mx-0 pt-3'}>
                            <Col className={'mx-0 px-3'}>
                                <h4 className={`p-0 mb-0 ${Styles.EventTitle}`}>{title}</h4>
                            </Col>
                        </Row>
                    )}
                </div>
            )}

            {width >= UI_FRAMEWORK_SM_WIDTH && (
                <Row className={'mx-0 w-100'}>
                    <Col className={`mb-3 d-flex justify-content-between align-items-center p-0`}>
                        <span className={Styles.EventTitle}>{title}</span>
                        <span onClick={onClosingModal} className="cursor-pointer">
                            <CloseIcon />
                        </span>
                    </Col>
                </Row>
            )}
        </Modal.Header>
    );
};
