import React, { FunctionComponent, useMemo } from 'react';
import { EventModel } from '@/events/models';
import { Button, ButtonType } from '@/components/form-controls/button';
import { TranslationService } from '@/services/translation';
import Styles from './Styles.module.scss';
import ShareIcon from '@/assets/icons/ShareIcon';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { RegistrationStatus } from '@/events/reducer/types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type Props = {
    event?: EventModel;
    onShareEventClick: () => void;
    onLeftButtonClick: () => void;
    onRightButtonClick: () => void;
    loading: boolean;
};

export const EventDetailsActions: FunctionComponent<Props> = ({
    event,
    onShareEventClick,
    onLeftButtonClick,
    onRightButtonClick,
    loading,
}) => {
    const registrationStatus = useSelector<RootState, RegistrationStatus>(
        (state) => state.events.registrationStatus
    );

    const rightButtonLabel = useMemo(() => {
        if (registrationStatus === RegistrationStatus.CONFIRMING) {
            return TranslationService.getInstance().getPhrase('registration.buttons.confirm');
        }

        if (registrationStatus === RegistrationStatus.AFTER_CONFIRMATION) {
            return TranslationService.getInstance().getPhrase(
                'registration.buttons.back_to_events'
            );
        }

        return event && event.isAuthenticatedUserRegistered
            ? TranslationService.getInstance().getPhrase('registration.buttons.unregister')
            : TranslationService.getInstance().getPhrase('registration.buttons.register');
    }, [registrationStatus, event]);

    return (
        <div className={Styles.EventDetailsActions}>
            <div className={'d-xl-block d-lg-block d-md-block d-sm-none d-none'}>
                <Row className={'mx-0'}>
                    <Col className={'px-0 d-flex justify-content-start align-items-center'}>
                        {[RegistrationStatus.VIEWING_DETAILS].includes(registrationStatus) && (
                            <Button
                                type={ButtonType.LINK_BUTTON}
                                onClick={onShareEventClick}
                                customClassName={`px-2 py-2 text-nowrap text-normal`}
                                disabled={loading}
                            >
                                <span>
                                    {TranslationService.getInstance().getPhrase(
                                        'registration.buttons.share'
                                    )}
                                </span>
                                <span className="ms-2">
                                    <ShareIcon />
                                </span>
                            </Button>
                        )}
                    </Col>

                    <Col className={'px-0 d-flex justify-content-end align-items-center'}>
                        <Button
                            type={ButtonType.DARK_OUTLINE_BLUE}
                            onClick={onLeftButtonClick}
                            customClassName={`px-3 py-2 w-100 me-2`}
                            disabled={loading}
                        >
                            {[RegistrationStatus.AFTER_CONFIRMATION].includes(registrationStatus)
                                ? TranslationService.getInstance().getPhrase(
                                      'registration.buttons.view_my_events'
                                  )
                                : TranslationService.getInstance().getPhrase(
                                      'registration.buttons.back_to_events'
                                  )}
                        </Button>

                        <Button
                            type={ButtonType.PRIMARY_BLUE}
                            customClassName={`${Styles.RegisterEventBtn} px-4 py-2 w-100`}
                            disabled={loading}
                            onClick={onRightButtonClick}
                        >
                            {rightButtonLabel}
                        </Button>
                    </Col>
                </Row>
            </div>

            <div className={`d-xl-none d-lg-none d-md-none d-sm-block`}>
                <div
                    className={`d-flex align-items-center justify-content-between ${Styles.EventBtns} px-2`}
                >
                    {registrationStatus === RegistrationStatus.VIEWING_DETAILS && (
                        <>
                            <Button
                                type={ButtonType.LINK_BUTTON}
                                onClick={onShareEventClick}
                                customClassName={`px-2 py-2 text-nowrap text-normal w-100`}
                                disabled={loading}
                            >
                                <span>
                                    {TranslationService.getInstance().getPhrase(
                                        'registration.buttons.share'
                                    )}
                                </span>
                                <span className="ms-2">
                                    <ShareIcon />
                                </span>
                            </Button>

                            <span className={'px-2'} />
                        </>
                    )}

                    {[
                        RegistrationStatus.CONFIRMING,
                        RegistrationStatus.AFTER_CONFIRMATION,
                    ].includes(registrationStatus) && (
                        <>
                            <Button
                                type={ButtonType.LIGHT_BLUE}
                                onClick={onLeftButtonClick}
                                customClassName={`px-4 py-2 w-100`}
                                disabled={loading}
                            >
                                {[RegistrationStatus.AFTER_CONFIRMATION].includes(
                                    registrationStatus
                                )
                                    ? TranslationService.getInstance().getPhrase(
                                          'registration.buttons.view_my_events'
                                      )
                                    : TranslationService.getInstance().getPhrase(
                                          'registration.buttons.back_to_events'
                                      )}
                            </Button>

                            <span className={'px-2'} />
                        </>
                    )}

                    <Button
                        type={ButtonType.PRIMARY_BLUE}
                        customClassName={`${Styles.RegisterEventBtn} px-4 py-2 w-100`}
                        disabled={loading}
                        onClick={onRightButtonClick}
                    >
                        {rightButtonLabel}
                    </Button>
                </div>
            </div>
        </div>
    );
};
