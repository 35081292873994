import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { EventModel, EventSubtype } from '@/events/models';
import { EventFilters, EventsGroup } from '@/events/reducer/types';
import { DateModel } from '@/utilities/dates';

export const useEventsGrouped = () => {
    const events = useSelector<RootState, EventModel[]>((state) => state.events.fetchedEvents);

    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const [eventsGroups, setEventsGroups] = useState<EventsGroup[]>([]);

    const eventsToGroup = useMemo(() => {
        if (filters.onlyRegisteredEvents) {
            return events;
        }

        return events.filter(
            (event) => ![EventSubtype.TOWN_HALL, EventSubtype.AMBASSADOR].includes(event.subtype)
        );
    }, [events, filters]);

    const loadEventsGroups = useCallback((newEvents: EventModel[]) => {
        const currentTimezone = new DateModel().getTimezone();

        const eventsGroupBySunday: { [index: number]: EventsGroup } = {};

        newEvents.forEach((event) => {
            const eventDate = new DateModel(event.startDateInMilliseconds);
            eventDate.setTimezone(currentTimezone);
            const weekOfEvent = eventDate.getWeek();

            if (eventsGroupBySunday[weekOfEvent['sunday'].timestamp]) {
                eventsGroupBySunday[weekOfEvent['sunday'].timestamp].eventIds.push(event.id);

                eventsGroupBySunday[weekOfEvent['sunday'].timestamp].events.push(event);
            } else {
                eventsGroupBySunday[weekOfEvent['sunday'].timestamp] = {
                    fromDate: `${weekOfEvent['sunday'].month} ${weekOfEvent['sunday'].day}`,
                    toDate:
                        weekOfEvent['saturday'].month === weekOfEvent['sunday'].month
                            ? `${weekOfEvent['saturday'].day}`
                            : `${weekOfEvent['saturday'].month} ${weekOfEvent['saturday'].day}`,
                    events: [event],
                    eventIds: [event.id],
                };
            }
        });

        const timestamps = Object.keys(eventsGroupBySunday).sort();

        const newEventsGroups = timestamps.map((timestamp) => {
            return eventsGroupBySunday[Number(timestamp)];
        });

        setEventsGroups(newEventsGroups);
    }, []);

    useEffect(() => {
        loadEventsGroups(eventsToGroup);
    }, [eventsToGroup, loadEventsGroups]);

    return eventsGroups;
};
