import React, { FunctionComponent, useMemo } from 'react';
import { CLIENTS } from '@/constants/clients';
import Styles from './Styles.module.scss';

type Props = {
    backgroundColor: string;
    color?: string;
    client?: string;
};

export const ClientBadge: FunctionComponent<Props> = ({ backgroundColor, color, client }) => {
    const imageUrl = useMemo((): string | undefined => {
        if (!client) {
            return undefined;
        }

        const clientFound = Object.keys(CLIENTS).find(
            (item: string) => CLIENTS[item].name === client
        );

        if (!clientFound) {
            return undefined;
        }

        return CLIENTS[clientFound].imageUrl;
    }, [client]);

    return (
        <>
            {imageUrl && (
                <span
                    className={`${Styles.ClientBadge} py-1 px-4 d-flex align-items-center justify-content-center`}
                    style={{ backgroundColor, color }}
                >
                    {imageUrl && (
                        <img src={imageUrl} alt="wider-circle-logo" className={Styles.ClientLogo} />
                    )}
                </span>
            )}
        </>
    );
};
