import ErrorBoundaryImage from '@/assets/images/LogoPortrait.png';
import MyLocationIcon from '@/assets/images/icons/my-location-icon.png';
import EventMarkerIcon from '@/assets/images/icons/event-marker-icon.png';
import EventMarkerIconSelected from '@/assets/images/icons/event-marker-icon-selected.png';
import MapPin from '@/assets/images/icons/wc-pin.png';
import MapPinSelected from '@/assets/images/icons/wc-pin-selected.png';

// Icons
import ClockIcon from '@/assets/images/icons/clock-icon.png';
import CalendarIcon from '@/assets/images/icons/calendar-icon.png';
import MapLocationIcon from '@/assets/images/icons/map-location-icon.png';
import DirectionSignIcon from '@/assets/images/icons/direction-sign-icon.png';
import ArrowDownIcon from '@/assets/images/icons/arrow-down.png';
import ArrowUpIcon from '@/assets/images/icons/arrow-up.png';
import ArrowLeftIcon from '@/assets/images/icons/arrow-left.png';
import ArrowRightIcon from '@/assets/images/icons/arrow-right.png';
import CheckboxCheckedIcon from '@/assets/images/icons/checkbox-checked.png';
import CheckboxUncheckedIcon from '@/assets/images/icons/checkbox-unchecked.png';
import RadioCheckedIcon from '@/assets/images/icons/radio-checked.png';
import RadioUncheckedIcon from '@/assets/images/icons/radio-unchecked.png';

import CloseIcon from '@/assets/images/icons/close.png';
import CloseCircleIcon from '@/assets/images/icons/close-circle.png';
import CloseCircleFilledIcon from '@/assets/images/icons/close-circle-filled.png';
import HeadsetIcon from '@/assets/images/icons/headset.png';
import LocatorIcon from '@/assets/images/icons/locator-icon.png';
import FilterIcon from '@/assets/images/icons/filter.png';
import ViewMapIcon from '@/assets/images/icons/view-map.png';
import HideMapIcon from '@/assets/images/icons/hide-map.png';
import LineArrowRightIcon from '@/assets/images/icons/line-arrow-right.png';
import DownloadIcon from '@/assets/images/icons/Download.svg';

import LocatorGrayIcon from '@/assets/images/icons/locator-gray.png';
import LocatorWhiteIcon from '@/assets/images/icons/locator-white.png';
import ListGrayIcon from '@/assets/images/icons/list-gray.png';
import ListWhiteIcon from '@/assets/images/icons/list-white.png';
import RegisteredUsersIcon from '@/assets/images/icons/registered-users.png';
import RegisteredIcon from '@/assets/images/icons/registered-icon.png';
import PhoneIcon from '@/assets/images/icons/phone.png';
import GoogleCalendarIcon from '@/assets/images/icons/google-calendar.png';
import AppleIcon from '@/assets/images/icons/apple.png';
import SearchIcon from '@/assets/images/icons/search-2.png';
import InfoCircleTransparentIcon from '@/assets/images/icons/info-circle-transparent.png';
import InfoCircleBlueIcon from '@/assets/images/icons/info-circle-blue.png';

// Steps Images
import StepOneImageOne from '@/assets/images/steps/step-1-slide-image-1.png';
import StepOneImageTwo from '@/assets/images/steps/step-1-slide-image-2.png';
import StepOneImageThree from '@/assets/images/steps/step-1-slide-image-3.png';
import StepOneImageFour from '@/assets/images/steps/step-1-slide-image-4.png';
import StepOneImageFive from '@/assets/images/steps/step-1-slide-image-5.png';
import step_1_mobile from '@/assets/images/steps/step_1-mobile.png';

// Images
import RegCompleteImage from '@/assets/images/RegCompleteImage.png';
import LogoSquare from '@/assets/images/logo-square.png';
import GoogleMapsLogo from '@/assets/images/google-maps-logo.png';
import AppleMapsLogo from '@/assets/images/apple-maps-logo.png';

export const IMAGES: {
    [name: string]: string;
} = {
    general_0: 'https://storage.googleapis.com/public_website_ss/general_0.jpg',
    general_1: 'https://storage.googleapis.com/public_website_ss/general_1.jpg',
    general_2: 'https://storage.googleapis.com/public_website_ss/general_2.jpg',
    general_3: 'https://storage.googleapis.com/public_website_ss/general_3.png',
    general_4: 'https://storage.googleapis.com/public_website_ss/general_4.jpeg',
    general_5: 'https://storage.googleapis.com/public_website_ss/general_5.jpg',
    general_6: 'https://storage.googleapis.com/public_website_ss/general_6.jpeg',
    general_7: 'https://storage.googleapis.com/public_website_ss/general_7.jpg',
    general_8: 'https://storage.googleapis.com/public_website_ss/general_8.png',
    general_9: 'https://storage.googleapis.com/public_website_ss/general_9.jpeg',
    general_10: 'https://storage.googleapis.com/public_website_ss/general_10.jpg',
    general_11: 'https://storage.googleapis.com/public_website_ss/general_11.jpg',
    general_12: 'https://storage.googleapis.com/public_website_ss/general_12.jpg',
    general_13: 'https://storage.googleapis.com/public_website_ss/general_13.jpg',
    general_14: 'https://storage.googleapis.com/public_website_ss/general_14.jpg',
    general_15: 'https://storage.googleapis.com/public_website_ss/general_15.jpg',
    general_16: 'https://storage.googleapis.com/public_website_ss/general_16.jpg',
    general_17: 'https://storage.googleapis.com/public_website_ss/general_17.jpg',
    general_18: 'https://storage.googleapis.com/public_website_ss/general_18.jpg',
    general_19: 'https://storage.googleapis.com/public_website_ss/general_19.jpg',
    general_20: 'https://storage.googleapis.com/public_website_ss/general_20.jpg',
    general_21: 'https://storage.googleapis.com/public_website_ss/general_21.jpg',
    general_22: 'https://storage.googleapis.com/public_website_ss/general_22.jpg',
    general_23: 'https://storage.googleapis.com/public_website_ss/general_23.jpg',
    general_24: 'https://storage.googleapis.com/public_website_ss/general_24.jpg',
    general_25: 'https://storage.googleapis.com/public_website_ss/general_25.jpg',
    general_26: 'https://storage.googleapis.com/public_website_ss/general_26.jpg',
    general_27: 'https://storage.googleapis.com/public_website_ss/general_27.jpg',
    general_28: 'https://storage.googleapis.com/public_website_ss/general_28.jpg',
    general_29: 'https://storage.googleapis.com/public_website_ss/general_29.jpg',
    bcc_0: 'https://storage.googleapis.com/public_website_ss/bcc_0.jpg',
    bcc_1: 'https://storage.googleapis.com/public_website_ss/bcc_1.jpg',
    bcc_2: 'https://storage.googleapis.com/public_website_ss/bcc_2.jpg',
    bcc_3: 'https://storage.googleapis.com/public_website_ss/bcc_3.jpg',
    bcc_4: 'https://storage.googleapis.com/public_website_ss/bcc_4.jpg',
    bcc_5: 'https://storage.googleapis.com/public_website_ss/bcc_5.jpg',
    bcc_6: 'https://storage.googleapis.com/public_website_ss/bcc_6.jpg',
    bcc_7: 'https://storage.googleapis.com/public_website_ss/bcc_7.jpg',
    ErrorBoundaryImage,
    MyLocationIcon,
    EventMarkerIcon,
    EventMarkerIconSelected,
    MapPin,
    MapPinSelected,
    ClockIcon,
    CalendarIcon,
    MapLocationIcon,
    DirectionSignIcon,
    DownloadIcon,
    // Steps Images
    StepOneImageOne,
    StepOneImageTwo,
    StepOneImageThree,
    StepOneImageFour,
    StepOneImageFive,
    step_1_mobile,
    CloseIcon,
    CloseCircleIcon,
    CloseCircleFilledIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    CheckboxCheckedIcon,
    CheckboxUncheckedIcon,
    RadioCheckedIcon,
    RadioUncheckedIcon,
    HeadsetIcon,
    LocatorIcon,
    FilterIcon,
    ViewMapIcon,
    HideMapIcon,
    LineArrowRightIcon,
    RegCompleteImage,
    LogoSquare,
    LocatorGrayIcon,
    LocatorWhiteIcon,
    ListGrayIcon,
    ListWhiteIcon,
    RegisteredUsersIcon,
    RegisteredIcon,
    PhoneIcon,
    GoogleCalendarIcon,
    AppleIcon,
    SearchIcon,
    GoogleMapsLogo,
    AppleMapsLogo,
    InfoCircleTransparentIcon,
    InfoCircleBlueIcon,
};
