import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { google, ics } from 'calendar-link';
import { EventModel } from '@/events/models';
import { Button, ButtonType } from '@/components/form-controls/button';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';
import { DateModel } from '@/utilities/dates';
import { useContainerDimensions } from '@/hooks/use-container-dimensions';
import { TranslationService } from '@/services/translation';

type Props = {
    event: EventModel;
    customWidth?: string | number;
    direction?: 'up' | 'down';
};

export const AddEventToCalendar: FunctionComponent<Props> = ({
    event,
    customWidth,
    direction = 'down',
}) => {
    const [areOptionsDisplayed, displayOptions] = useState(false);
    const containerRef = useRef(null);

    const { width: containerWidth } = useContainerDimensions(containerRef);

    const calendarEvent = useMemo(
        () => ({
            title: event.name,
            description: event.description,
            start: new DateModel(event.startDateInMilliseconds).format('llll'),
            end: new DateModel(event.startDateInMilliseconds).format('llll'),
            location: `${event.venue.city} ${event.venue.state} ${event.venue.zipcode}`,
        }),
        [event]
    );

    const menuCurtainHeight = useMemo(() => (areOptionsDisplayed ? 120 : 0), [areOptionsDisplayed]);

    const optionsContainerDirectionClassname = useMemo(
        () =>
            direction === 'up'
                ? `${Styles.OptionsContainerUp} mb-2`
                : `${Styles.OptionsContainerDown} mt-2`,
        [direction]
    );

    const curtainClassname = useMemo(() => {
        const mainClassname =
            direction === 'up'
                ? `${Styles.MenuCurtain} ${Styles.MenuCurtainUp}`
                : `${Styles.MenuCurtain} ${Styles.MenuCurtainDown}`;

        if (!areOptionsDisplayed) {
            return mainClassname;
        }

        return direction === 'up'
            ? `${mainClassname} ${Styles.ScrollUp}`
            : `${mainClassname} ${Styles.ScrollDown}`;
    }, [direction, areOptionsDisplayed]);

    const clickHandler = useCallback(() => {
        displayOptions(!areOptionsDisplayed);
    }, [areOptionsDisplayed]);

    const googleCalendarClickHandler = useCallback(() => {
        window.open(google(calendarEvent), '_blank');
    }, [calendarEvent]);

    const appleCalendarClickHandler = useCallback(() => {
        window.open(ics(calendarEvent), '_blank');
    }, [calendarEvent]);

    return (
        <div
            ref={containerRef}
            className={`${Styles.AddEventToCalendar}`}
            style={{ width: customWidth || 'auto' }}
        >
            <Button
                type={ButtonType.LIGHT_GRAY_BLUE}
                onClick={clickHandler}
                customClassName={`px-4 py-2 w-100`}
            >
                {TranslationService.getInstance().getPhrase(
                    'registration.after_registration.buttons.add_calendar'
                )}
            </Button>

            <div
                className={`${curtainClassname}`}
                style={{
                    width: containerWidth,
                    height: menuCurtainHeight,
                }}
            />

            {areOptionsDisplayed && (
                <div
                    className={`${Styles.OptionsContainer} ${optionsContainerDirectionClassname} ${
                        areOptionsDisplayed && Styles.Opened
                    } flex-column`}
                    style={{ width: containerWidth }}
                >
                    <div
                        className={' d-flex align-items-center pt-3 pb-2 px-3'}
                        onClick={googleCalendarClickHandler}
                    >
                        <img alt="google-calendar-icon" src={IMAGES.GoogleCalendarIcon} />
                        <span className={'ps-3'}>Google Calendar</span>
                    </div>

                    <div
                        className={' d-flex align-items-center pt-2 pb-3 px-3'}
                        onClick={appleCalendarClickHandler}
                    >
                        <img alt="google-calendar-icon" src={IMAGES.AppleIcon} />
                        <span className={'ps-3'}>Apple Calendar</span>
                    </div>
                </div>
            )}
        </div>
    );
};
