import React, {
    ChangeEvent,
    FunctionComponent,
    KeyboardEvent,
    useCallback,
    useMemo,
    useState,
    useEffect,
} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Styles from './Styles.module.scss';
import { TooltipContent } from '@/components/form-controls/input-v2/tooltip-content';
import { TranslationService } from '@/services/translation';
import './tooltip.css';

export type InputBoxUIOptions = {
    containerHeight?: number | string;
    containerWidth?: number | string;
};

type Props = {
    label: string;
    placeholder?: string;
    type: string;
    value: string | undefined;
    onChange: (value: string) => void;
    onEnter?: () => void;
    isValid: boolean;
    uiOptions?: InputBoxUIOptions;
    showTooltipInitially?: boolean;
};

export const ZipcodeBox: FunctionComponent<Props> = ({
    label,
    placeholder,
    type,
    value,
    onChange,
    onEnter = () => null,
    isValid,
    uiOptions = {},
    showTooltipInitially = false,
}) => {
    const [focused, setFocused] = useState(false);
    const [isTooltipDisplayed, displayTooltip] = useState(showTooltipInitially);

    const inputClassName: string = useMemo(
        () => `w-100  ${!isValid && Styles.InputInvalid} h-100'}`,
        [isValid]
    );

    const changeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const {
                target: { value: newValue },
            } = event;

            onChange(newValue);
        },
        [onChange]
    );

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key?.toLowerCase() === 'enter') {
                onEnter();
                setFocused(false);
            }
        },
        [onEnter]
    );

    const onFocus = useCallback(() => {
        setFocused(true);
        displayTooltip(true);
    }, []);

    const onBlur = useCallback(() => {
        setFocused(false);
    }, []);

    const onTooltipClose = useCallback(() => {
        displayTooltip(false);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            displayTooltip(false);
        }, 8000);
    }, []);

    const renderTooltip = (props: any) => {
        return (
            <Tooltip {...props} id="tooltip-container">
                <TooltipContent
                    label={TranslationService.getInstance().getPhrase(
                        'filters.bar.zipcode.tooltip.label'
                    )}
                    onClose={onTooltipClose}
                />
            </Tooltip>
        );
    };

    return (
        <div
            className={`${Styles.Input} d-flex flex-column align-items-start w-100`}
            style={{ height: uiOptions.containerHeight || 68 }}
        >
            {label && (
                <label htmlFor="first_name" className={`${!isValid && Styles.InvalidLabel} mb-1`}>
                    {label}
                </label>
            )}

            <div
                className={`${Styles.InputContainer} ${
                    focused && Styles.Focused
                } w-100 px-3 py-2 d-flex align-items-center`}
            >
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                    show={isTooltipDisplayed}
                >
                    <input
                        id="zipcode-search"
                        type={type}
                        className={inputClassName}
                        placeholder={placeholder}
                        onChange={changeHandler}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={value}
                    />
                </OverlayTrigger>
            </div>
        </div>
    );
};
