import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { EventModel } from '@/events/models';
import { EventRegistrationConnectorProps } from '@/events/pages/event-registration/connector';
import { EventRegistrationCompleteModal } from '@/events/components/event-registration-complete-modal/event-registration-complete-modal';
import { ShareModal } from '@/events/components/share-modal';
import Styles from './Styles.module.scss';
import { Config } from '@/utilities/config';
import { EventDetailsHeader } from '@/events/components/event-details-header';
import { EventDetailsActions } from '@/events/components/event-details-actions';
import { RegistrationStatus } from '@/events/reducer/types';
import { useDispatch } from 'react-redux';
import { eventsActions } from '@/events/reducer';
import { useRegistrationOperations } from '@/events/pages/event-registration/components/use-registration-operations';
import { ViewDetailsStatus } from '@/events/components/registration-statuses/view-details-status';
import { ConfirmingStatus } from '@/events/components/registration-statuses/confirming-status';
import { AfterConfirmationStatus } from '@/events/components/registration-statuses/after-confirmation-status';
import { useContainerDimensions } from '@/hooks/use-container-dimensions';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { UI_FRAMEWORK_SM_WIDTH } from '@/constants/layout';

type Props = EventRegistrationConnectorProps & {
    showBackAllEvents: () => void;
    fetchUpcomingWeeklyEvents: (params: { eventId: any; clients: any }) => any;
    openAuthenticationModal: (afterAuthenticationCallback: () => void) => void;
};

export const EventDetailsModal: FunctionComponent<Props> = ({
    showBackAllEvents,
    isLoadingEvents,
    selectedEvent,
    authenticatedUser,
    selectEvent,
    queryParameters,
    fetchedUpcomingWeeklyEvents,
    fetchUpcomingWeeklyEvents,
    fetchedClients,
    registrationStatus,
    filters,
    fetchEvents,
    fetchEventsForMap,
}) => {
    const navigate = useNavigate();
    const divRef = useRef(null);
    const [loading, setLoading] = useState<boolean>(false);

    const { width } = useWindowDimensions();

    const whatIsNextRef = useRef(null);

    const dispatch = useDispatch();

    const { height: whatIsNextContainerHeight } = useContainerDimensions(whatIsNextRef);

    const bodyClassName = useMemo(() => {
        if (width > UI_FRAMEWORK_SM_WIDTH) {
            return `p-0 ${Styles.EventDetailsModalBody}`;
        }

        return registrationStatus === RegistrationStatus.VIEWING_DETAILS
            ? `p-0 ${Styles.EventDetailsModalBody} ${Styles.EventDetailsModalBodyBigTopGap}`
            : `p-0 ${Styles.EventDetailsModalBody} ${Styles.EventDetailsModalBodySmallTopGap}`;
    }, [registrationStatus, width]);

    const errorHandler = useCallback(() => {
        setLoading(false);
    }, []);

    const successHandler = useCallback(() => {
        setLoading(false);
        dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.AFTER_CONFIRMATION));
    }, [dispatch]);

    const { register } = useRegistrationOperations({
        authenticatedUser,
        clients: selectedEvent?.clients || queryParameters.clients,
        onError: errorHandler,
        onSuccess: successHandler,
    });

    const handleEventRegisterModal = useCallback(async () => {
        if (!authenticatedUser) {
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.AUTHENTICATING));
            return;
        }

        if (registrationStatus === RegistrationStatus.VIEWING_DETAILS) {
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.CONFIRMING));
            return;
        }

        if (registrationStatus === RegistrationStatus.CONFIRMING) {
            setLoading(true);

            await register(
                selectedEvent?.id as string,
                authenticatedUser.canItBeContactedThroughEmail,
                authenticatedUser.canItBeContactedThroughPhone,
                true,
                Boolean(selectedEvent?.isAuthenticatedUserRegistered)
            );

            dispatch(eventsActions.updateAllEventIds([]));
            fetchEventsForMap();

            if (selectedEvent?.isAuthenticatedUserRegistered) {
                dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.VIEWING_EVENTS));
                dispatch(eventsActions.selectEvent(undefined));
            }

            return;
        }

        if (registrationStatus === RegistrationStatus.AFTER_CONFIRMATION) {
            if (queryParameters.eventId) {
                navigate('/');
            }
            showBackAllEvents();
        }
    }, [
        authenticatedUser,
        registrationStatus,
        dispatch,
        register,
        selectedEvent?.id,
        selectedEvent?.isAuthenticatedUserRegistered,
        fetchEvents,
        filters,
        fetchEventsForMap,
        queryParameters.eventId,
        showBackAllEvents,
        navigate,
    ]);

    const handleSelectionOfEvent = useCallback(
        (event: EventModel) => {
            selectEvent(event);
            // @ts-ignore
            divRef?.current.dialog.firstChild.children[0].scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        [selectEvent, divRef]
    );

    const [isShareModalDisplayed, displayShareModal] = useState(false);

    const sharingHandler = useCallback(() => {
        const eventParameter = selectedEvent?.id;

        const clientNamesOnEvent = selectedEvent?.clients.map((eventClient) =>
            eventClient.toLowerCase()
        );

        const clientFound = fetchedClients.find((item) => {
            const nameFound = item.nameOnEvents.find((name) =>
                clientNamesOnEvent?.includes(name.toLowerCase())
            );

            return nameFound !== undefined;
        });

        const clientParameter = clientFound ? clientFound.parameter : undefined;

        const linkToShare = `${Config.SelfServeSharingUrl}?client=${clientParameter}&event=${eventParameter}`;

        navigator.clipboard.writeText(linkToShare);
        displayShareModal(true);
    }, [fetchedClients, selectedEvent?.clients, selectedEvent?.id]);

    const closingSharingModalHandler = useCallback(() => {
        displayShareModal(false);
    }, []);

    const onClosingModal = useCallback(() => {
        if (registrationStatus === RegistrationStatus.CONFIRMING) {
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.VIEWING_DETAILS));
            return;
        }

        if (queryParameters.eventId) {
            navigate('/');
        }
        showBackAllEvents();
    }, [navigate, queryParameters.eventId, showBackAllEvents, registrationStatus]);

    const onLeftButtonClick = useCallback(() => {
        if (registrationStatus === RegistrationStatus.AFTER_CONFIRMATION) {
            dispatch(eventsActions.updateOnlyRegisteredEventsFilter(true));
        }

        showBackAllEvents();
    }, [showBackAllEvents, registrationStatus, dispatch]);

    useEffect(() => {
        if (selectedEvent) {
            fetchUpcomingWeeklyEvents({
                eventId: selectedEvent.id,
                clients: selectedEvent.clients,
            });
        }
    }, [fetchUpcomingWeeklyEvents, selectedEvent]);

    return (
        <Modal
            dialogClassName={Styles.EventDetailsModalDialog}
            contentClassName={Styles.EventDetailsModalContent}
            animation={false}
            className="modal-right-to-left"
            show={selectedEvent !== undefined}
            onHide={showBackAllEvents}
            ref={divRef}
        >
            {isShareModalDisplayed && (
                <ShareModal
                    isOpen={isShareModalDisplayed}
                    onClose={closingSharingModalHandler}
                    message={'The link of this event is now copied to your clipboard'}
                    title={'Event link copied!'}
                />
            )}

            {registrationStatus === RegistrationStatus.WHAT_IS_NEXT && selectedEvent && (
                <EventRegistrationCompleteModal
                    event={selectedEvent}
                    authenticatedUser={authenticatedUser}
                    showBackAllEvents={onClosingModal}
                    registrationComplete={true}
                    clients={queryParameters.clients}
                    events={fetchedUpcomingWeeklyEvents}
                    selectedEventIds={fetchedUpcomingWeeklyEvents.map((event) => event.id)}
                    isLoading={isLoadingEvents}
                    onViewDetails={() => {}}
                />
            )}

            <EventDetailsHeader onClosingModal={onClosingModal} event={selectedEvent} />

            <Modal.Body className={bodyClassName}>
                <>
                    {registrationStatus === RegistrationStatus.VIEWING_DETAILS && (
                        <ViewDetailsStatus
                            onClosingModal={onClosingModal}
                            handleSelectionOfEvent={handleSelectionOfEvent}
                        />
                    )}

                    {registrationStatus === RegistrationStatus.CONFIRMING && (
                        <ConfirmingStatus onClosingModal={onClosingModal} />
                    )}

                    {registrationStatus === RegistrationStatus.AFTER_CONFIRMATION && (
                        <AfterConfirmationStatus
                            handleSelectionOfEvent={handleSelectionOfEvent}
                            containerRef={whatIsNextRef}
                            event={selectedEvent as EventModel}
                        />
                    )}

                    <EventDetailsActions
                        event={selectedEvent}
                        loading={loading}
                        onShareEventClick={sharingHandler}
                        onLeftButtonClick={onLeftButtonClick}
                        onRightButtonClick={handleEventRegisterModal}
                    />
                </>
            </Modal.Body>
        </Modal>
    );
};
