import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { EventModel } from '@/events/models';
import { IMAGES } from '@/assets/images';
import Styles from './Styles.module.scss';

const MAX_NUMBER_OF_DOTS = 5;

type Props = {
    events: EventModel[];
    onSelectedEvent: (event: EventModel) => void;
    onPreviousEvent: () => void;
    onNextEvent: () => void;
    selectedEvent: EventModel;
};

export const Indicators: FunctionComponent<Props> = ({
    events,
    onSelectedEvent,
    selectedEvent,
    onPreviousEvent,
    onNextEvent,
}) => {
    const [eventsToSelect, setEventsToSelect] = useState<EventModel[]>([]);

    useEffect(() => {
        if (
            eventsToSelect.length !== 0 &&
            !eventsToSelect.find((event) => event.id === selectedEvent.id)
        ) {
            const selectedEventIndex = events.findIndex((event) => event.id === selectedEvent.id);
            const firstEventIndex = events.findIndex((event) => event.id === eventsToSelect[0].id);
            const lastEventIndex = events.findIndex(
                (event) => event.id === eventsToSelect[eventsToSelect.length - 1].id
            );

            if (selectedEventIndex < firstEventIndex) {
                setEventsToSelect(
                    events.slice(selectedEventIndex, selectedEventIndex + MAX_NUMBER_OF_DOTS)
                );
            }

            if (selectedEventIndex > lastEventIndex) {
                setEventsToSelect(
                    events.slice(
                        selectedEventIndex - (MAX_NUMBER_OF_DOTS - 1),
                        selectedEventIndex + 1
                    )
                );
            }
        }
    }, [selectedEvent, events, eventsToSelect]);

    const disabledClassNameLeftIcon = useMemo(() => {
        if (events.length === 0 || !selectedEvent || events[0].id === selectedEvent.id) {
            return Styles.Disabled;
        }
        return null;
    }, [events, selectedEvent]);

    const disabledClassNameRightIcon = useMemo(() => {
        if (
            events.length === 0 ||
            !selectedEvent ||
            events[events.length - 1].id === selectedEvent.id
        ) {
            return Styles.Disabled;
        }
        return null;
    }, [events, selectedEvent]);

    useEffect(() => {
        setEventsToSelect(events.slice(0, MAX_NUMBER_OF_DOTS));
    }, [events]);

    if (eventsToSelect.length <= 1) {
        return null;
    }

    return (
        <div className={`${Styles.Indicators} d-flex flex-row align-items-center w-100 py-3 px-2`}>
            <div
                className={`${Styles.ArrowIcon} ${disabledClassNameLeftIcon} d-flex align-items-center justify-content-start me-4`}
                onClick={onPreviousEvent}
            >
                <img alt="arrow-left-icon-indicator" src={IMAGES.ArrowLeftIcon} />
            </div>

            <div className={'d-flex flex-row align-items-center justify-content-center'}>
                {eventsToSelect.map((event, index) => (
                    <div
                        key={event.id}
                        className={`${Styles.Dot} ${selectedEvent.id === event.id && Styles.Selected} mx-2`}
                        onClick={() => onSelectedEvent(event)}
                    ></div>
                ))}
            </div>

            <div
                className={`${Styles.ArrowIcon} ${disabledClassNameRightIcon} d-flex align-items-center justify-content-end ms-4`}
                onClick={onNextEvent}
            >
                <img alt="arrow-right-icon-indicator" src={IMAGES.ArrowRightIcon} />
            </div>
        </div>
    );
};
